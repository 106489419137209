import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Divider, Icon, Segment } from 'semantic-ui-react';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdfworker/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// import ajax from './agent.js'
// import utility from './utility.js'


function PdfPreview(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    // useEffect(() => {
    //     console.log("PDFFFFFFFFFFFFF")
    //     return () => {

    //     }
    // }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    function previousPage() {
        changePage(-1);
    }
    function nextPage() {
        changePage(1);
    }

    // const linkStyle = { color: 'var(--customPrimary)', borderRadius: '4px', display: 'inline-block', padding: '2px 8px', marginRight: '4px', border: '1px solid' }
    // const activeLinkStyle = { background: 'var(--customPrimary)', color: '#fff', borderRadius: '4px', display: 'inline-block', padding: '2px 8px', marginRight: '4px', border: '1px solid' }

    return (
        <div>
            <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}
                error={<Segment basic textAlign='center' padded='very'>No Data</Segment>}
                noData={<Segment basic textAlign='center' padded='very'>No File Uploaded</Segment>}
                loading={<Segment loading basic padded='very'></Segment>}
            >
                <Page pageNumber={pageNumber} />
            </Document>

            {numPages && <>
                <Divider />
                <div className='flex flexJustifyCenter'>
                    <Button size='big' className='customBtn1Secondary py-0 border-0 mr-0'>
                        <Icon name='fast backward' disabled={pageNumber === 1} onClick={() => setPageNumber(1)} />
                    </Button>
                    <Button size='big' className='customBtn1Secondary py-0 border-0 mr-0' disabled={pageNumber <= 1} onClick={previousPage}>
                        <Icon name='backward' />
                    </Button>
                    <div className='flex flexJustifyCenter textMedium py-5px mx-1'>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</div>
                    <Button size='big' className='customBtn1Secondary py-0 border-0 mr-0' disabled={pageNumber >= numPages} onClick={nextPage}>
                        <Icon name='forward' />
                    </Button>
                    <Button size='big' className='customBtn1Secondary py-0 border-0 mr-0' disabled={pageNumber === numPages} onClick={() => setPageNumber(numPages)}>
                        <Icon name='fast forward' />
                    </Button>
                </div>
            </>}
        </div>
    );
}

export default PdfPreview
