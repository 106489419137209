/*
 * In order to make it easy to use SuperAgent for all our AJAX API calls, this file has a number of constants defined for each type of API call
 * (GET, POST, PUT, DELETE) and constants for each service where such API end-points exist. To make it easy to understand, each AJAX call is easy
 * to write once this agent.js file is imported like this: import ajax from <path to agent.js>;
 */

import superagent from 'superagent';
import UserDetails from "../userInfo/UserDetails.js";
import { CONFIG } from "../components/Login/config.js";
const API_ROOT = '';

const responseBody = res => res.body;

/*
 * Generic RESTful API calls
 */

const requests = {
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .set(`OAuthJWT`, `${UserDetails.OAuthJWT}`)
            .set(`Authentication`, `${UserDetails.Authentication}`),
    //.use()
    //.end(handleErrors,responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .set(`OAuthJWT`, `${UserDetails.OAuthJWT}`)
            .set(`Authentication`, `${UserDetails.Authentication}`),
    //.use()
    //.end(handleErrors,responseBody),
    put: (url) =>
        superagent
            .put(`${API_ROOT}${url}`)
            .set(`OAuthJWT`, `${UserDetails.OAuthJWT}`)
            .set(`Authentication`, `${UserDetails.Authentication}`),
    //.use()
    //.end(handleErrors,responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .set(`OAuthJWT`, `${UserDetails.OAuthJWT}`)
            .set(`Authentication`, `${UserDetails.Authentication}`),

    //.use()
    //.end(handleErrors,responseBody),
    putBody: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .set(`OAuthJWT`, `${UserDetails.OAuthJWT}`)
            .set(`Authentication`, `${UserDetails.Authentication}`),
    //.use()
    //.end(handleErrors,responseBody),

    postTokenRequest: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .set(`Accept`, `application/json`)
            .set(`Access-Control-Allow-Origin`, `${CONFIG.CLIENT_URL}`)
            .set(`Content-Type`, `application/x-www-form-urlencoded`)


};

const token = {
    get: url =>
        superagent
            .get(`${url}`)
            .set(`Authorization`, `Bearer ` + `${UserDetails.OAuthJWT}`),
    post: (url, body) =>
        superagent
            .post(`${url}`, body)
            .set(`Authorization`, `Bearer ` + `${UserDetails.OAuthJWT}`)

};
const dogbreed = {
    get: (filename) =>
        token.get("/dogbreeds/" + filename).responseType('blob')
};

/*
 * For User Authentication Using OpenID Connect
 */
const AuthOpenID = {
    sendTokenRequest: (authrequest) =>
        requests.postTokenRequest(`${CONFIG.TOKEN_ENDPOINT}`, authrequest)
}

/*
 * For User Authentication
 */
const Auth = {
    userAuth: () =>
        //requests.post('/UserAuthenticationService/api/datamarketplace'),
        requests.get('UserManagementService/guest/api/datamarketplace'),
    userLogout: () =>
        requests.post('/UserAuthenticationService/datamarketplace/logout/')
};

const UserManagementService = {
    guestAuth: () =>
        requests.get('UserManagementService/guest/api/datamarketplace'),
    guestDetails: () =>
        requests.post('UserManagementService/private/api/datamarketplace/get/details'),
    userLogout: () =>
        requests.get('/UserManagementService/guest/datamarketplace/logout'),
    uiconfig: () =>
        requests.get('/UserManagementService/guest/login/configuration'),
};
/*
 * For New Product or Want wizard to use Draft Data Product service
 *
 * The draftDataProduct here does not refer to the ViewModel for drafts.
 * It is a developer-defined string to name the RequestBody that's coming in.
 */
const Draft = {
    create: (draftDataProduct) =>
        requests.post('/DraftDataProduct/dataProductDraft/create', draftDataProduct),
    savePublish: (draftDataProduct) =>
        requests.post('/DraftDataProduct/dataProductDraft/saveAndPublish', draftDataProduct),
    listProducts: (userID) =>
        requests.get('/DraftDataProduct/dataProductDraft/listDraftProductsForTheUser/' + userID + '/draft/Sell'),
    listWants: (userID) =>
        requests.get('/DraftDataProduct/dataProductDraft/listDraftProductsForTheUser/' + userID + '/draft/Buy'),
    load: (draftDataProductId) =>
        requests.get('/DraftDataProduct/dataProductDraft/findByDataProductId/' + draftDataProductId)
};

/*
 * For Category master data from the Category Service
 */
const CategoryMaster = {
    create: (category) =>
        requests.post('/CategoryService/category/create', category),
    listCategories: () =>
        requests.get('/CategoryService/category/findAll'),
    load: (categoryId) =>
        requests.get('/CategoryService/category/' + categoryId),
    update: (category) =>
        requests.post('/CategoryService/category/update/', category),
    loadCategoryName: (categoryId) =>
        requests.get('/CategoryService/category/name/' + categoryId),
    loadSubCategoryName: (subcategoryId) =>
        requests.get('/CategoryService/subcategory/name/' + subcategoryId),
    fetchLatestUpdatedTime: () =>
        requests.get('/CategoryService/category/fetch/latestUpdatedTime')
};


/*
 * For Subscription master data from the Subscription Service
 */
const SubscriptionMaster = {

    create: (subscriptionPackage) =>
        requests.post('/SubscriptionService/subscriptionPackage/create', subscriptionPackage),
    listSubscriptionPackages: () =>
        requests.get('/ContractManagementService/subscription/package/fetchAll'),
    load: (subscriptionCode) =>
        requests.get('/SubscriptionService/subscriptionPackage/' + subscriptionCode),
    update: (subscriptionPackage) =>
        requests.post('/SubscriptionService/subscriptionPackage/update/', subscriptionPackage)
};

/*
 * For Attributes master data from the Attribute Service
 */
const AttributeMaster = {
    create: (attribute) =>
        requests.post('/AttributeService/attribute/create', attribute),
    listAttributes: () =>
        requests.get('/AttributeService/attribute/findAll'),
    update: (attributeId, attribute) =>
        requests.post('/AttributeService/attribute/update/' + attributeId, attribute),
    fetchLatestUpdatedAtTimestamp: () =>
        requests.get('/AttributeService/attribute/fetch/latestUpdatedTime'),
    delete: (attributes) =>
        requests.post('/AttributeService/attribute/delete/', attributes),
    bulkupload: (formdata) =>
        requests.post('/AttributeService/attribute/bulkupload', formdata),

};


const MetaDataService = {
    //AttributesTemplates
    createTemplate: (attributeTemplate) =>
        requests.post('/MetaDataService/create/attributeTemplate', attributeTemplate),
    updateTemplate: (attributeTemplate) =>
        requests.post('/MetaDataService/update/attributeTemplate', attributeTemplate),
    listAttributeTemplates: () =>
        requests.get('/MetaDataService/findAll/attributeTemplate'),
    deleteTemplate: (attributeTemplates) =>
        requests.post('/MetaDataService/delete/attributeTemplate', attributeTemplates),
    //Attributes
    create: (attribute) =>
        requests.post('/MetaDataService/create/attribute', attribute),
    listAttributes: () =>
        requests.get('/MetaDataService/findAll/attribute'),
    getAttributeByCode: (attributeCode) =>
        requests.get('/MetaDataService/find/attribute/code/' + attributeCode),
    update: (attribute) =>
        requests.post('/MetaDataService/update/attribute/', attribute),
    fetchLatestUpdatedAtTimestamp: () =>
        requests.get('/MetaDataService/attribute/fetch/latestUpdatedTime'),
    delete: (attributes) =>
        requests.post('/MetaDataService/delete/attribute/', attributes),
    removeAttributesFromTemplate: (attribute) =>
        requests.post('/MetaDataService/remove/attribute', attribute),
    bulkupload: (formdata) =>
        requests.post('/MetaDataService/bulk/attribute', formdata),

    createBusinessMetaData: (attribute) =>
        requests.post('/MetaDataService/create/businessmetadata', attribute),
    listBusinessMetaData: () =>
        requests.get('/MetaDataService/findAll/businessmetadata'),
    listBusinessMetaData2: () =>
        requests.get('/MetaDataService/fetch/allbusinessmetadata'),
    getBusinessMetaDataByCode: (attributeCode) =>
        requests.get('/MetaDataService/find/businessmetadata/code/' + attributeCode),
    getBusinessMetaDataInfoByCode: (attributeCode) =>
        requests.get('/MetaDataService/fetch/businessmetadatainfo/byattributecode/' + attributeCode),
    searchBusinessMetaData: (property) =>
        requests.get('/MetaDataService/find/businessmetadata/property/' + property),
    updateBusinessMetaData: (attribute) =>
        requests.post('/MetaDataService/update/businessmetadata', attribute),

    deleteBusinessMetaData: (attributes) =>
        requests.post('/MetaDataService/delete/businessMetaData', attributes),

    bulkuploadBusinessMetaData: (formdata) =>
        requests.post('/MetaDataService/bulk/businessmetadata', formdata),
    createDataSource: (dataSourceParent) =>
        requests.post('/MetaDataService/create/datasourceinformation', dataSourceParent),
    updateDataSource: (dataSourceParent) =>
        requests.putBody('/MetaDataService/update/datasourceparent', dataSourceParent),
    fetchallDataSource: () =>
        requests.get('/MetaDataService/fetchall/datasourceparents'),
    fetchDataSourceDetails: (dataSourceParentCode) =>
        requests.get('/MetaDataService/fetch/datasourcedetails/' + dataSourceParentCode),
    deleteDataSourceDetails: (dataSourceParentCode) =>
        requests.del('/MetaDataService/delete/datasourceparent/' + dataSourceParentCode),
    scanTechnicalMetaData: (dscode) =>
        requests.post('/MetaDataService/scan/' + dscode),
    fetchCompleteDsInfo: (dscode) =>
        requests.get('/MetaDataService/fetch/dataSourceInformation/' + dscode),
    extractionStatus: (status) =>
        requests.get('/MetaDataService/fetch/all/datasourceinfo/' + status),
    fetchBusinessMetadataByGlossaryCode: (bmCode) =>
        requests.get('/MetaDataService/find/attribute/businesscode/' + bmCode),
    fetchtmByCode: (code) =>
        requests.get('/MetaDataService/find/technicalmetadatabycode/' + code),
    fetchCategory: () =>
        requests.get('/MetaDataService/category/findcategories'),
    createCategory: (category) =>
        requests.post('/MetaDataService/category/create', category),
    updateCategory: (category) =>
        requests.putBody('/MetaDataService/category/update', category),
    fetchCategoryByCode: (categoryCode) =>
        requests.get('/MetaDataService/find/category/code/' + categoryCode),
    fetchSubCategory: (catCode) =>
        requests.get('/MetaDataService/fetch/subcategories/' + catCode),
    createSubCategory: (category) =>
        requests.post('/MetaDataService/subcategory/create', category),
    updateCategory: (category) =>
        requests.putBody('/MetaDataService/subcategory/update', category),
    fetchAttributeFromCodes: (codes) =>
        requests.post('/MetaDataService/find/attribute/code/list', codes),
    fetchAttributeCompleteInfoFromCodes: (codes) =>
        requests.post('/MetaDataService/find/attributecompletedetails/code/list', codes),


    createComplianceProp: (body) =>
        requests.post('/MetaDataService/create/complianceProp', body),
    createComplianceClass: (body) =>
        requests.post('/MetaDataService/create/complianceClass', body),
    getAllComplianceProps: () =>
        requests.get('/MetaDataService/findAll/complianceProp'),
    getAllComplianceClasses: () =>
        requests.get('/MetaDataService/findAll/complianceClass'),
    getCompliancePropByCode: (code) =>
        requests.get('/MetaDataService/find/complianceProp/code/' + code),
    addComplianceProp: (compBody, attCode) =>
        requests.post('/MetaDataService/add/complianceProps/' + attCode, compBody),
    addCompliancePropToBM: (compBody, bmCode) =>
        requests.post('/MetaDataService/add/complianceProps/bm/' + bmCode, compBody),
    removeComplianceProp: (compBody, attCode) =>
        requests.post('/MetaDataService/remove/complianceProps/' + attCode, compBody),
    getPropsByAttribute: (code) =>
        requests.get('/MetaDataService/find/complianceProp/attributeCode/' + code),
    getPropsByBM: (code) =>
        requests.get('/MetaDataService/find/complianceProp/bmCode/' + code),
    getAttributesByKeyValue: (key, value) =>
        requests.get('/MetaDataService/find/attribute/bycomp/key/' + key + '/value/' + value),
    getBMByKeyValue: (key, value) =>
        requests.get('/MetaDataService/find/BM/bycomp/key/' + key + '/value/' + value)

}
/*
 * For Attribute Templates master data from the Attribute Service
 */
const AttributeTemplateMaster = {
    create: (attributeTemplate) =>
        requests.post('/AttributeService/attributeTemplate/create', attributeTemplate),
    deleteTemplates: (attributeTemplates) =>
        requests.post('/AttributeService/attributeTemplate/delete', attributeTemplates),
    deleteAttributes: (attributeTemplate) =>
        requests.post('/AttributeService/attributeTemplateAttribute/delete', attributeTemplate),
    listAttributeTemplates: () =>
        requests.get('/AttributeService/attributeTemplate/findAll'),
    update: (attributeTemplateId, attributeTemplate) =>
        requests.post('/AttributeService/attributeTemplate/update/' + attributeTemplateId, attributeTemplate),
    listAttributeTemplatesByCategory: (categoryId) =>
        requests.get('/AttributeService/attributeTemplate/findAllByCategoryId/' + categoryId),
    listAttributeTemplatesByCategoryAndSubcategory: (categoryId, subcategoryId) =>
        requests.get('/AttributeService/attributeTemplate/findAllByCategoryIdAndSubcategoryId/' + categoryId + '/' + subcategoryId),
    listAttributeTemplatesDetails: () =>
        requests.get('/AttributeService/attributeTemplate/findAllTemplates'),
};

/*
 * For Data Product details from the Data Product Service
 */
const DataProduct = {
    findByCode: (dataProductCode) =>
        requests.get('/DataProduct/dataProduct/findByDataProductCode/' + dataProductCode),
    listProductWantByOrganizationBPID: (bpid) =>
        requests.get('/DataProductService/fetchProductWantByOrganization/' + bpid),

    //APIs of the new Data Product service

    //DRAFT APIs
    createDraftProduct: (dataproduct) =>
        requests.post('/DataProductService/draftProduct/create', dataproduct),
    publishDraftProduct: (dataproduct) =>
        requests.post('/DataProduct/draftProduct/publish', dataproduct),
    createDraftWant: (dataproduct) =>
        requests.post('/DataProduct/draftWant/create', dataproduct),
    publishDraftWant: (dataproduct) =>
        requests.post('/DataProduct/draftWant/publish', dataproduct),
    updateDraftProduct: (dataproduct) =>
        requests.post('/DataProduct/draftProduct/update', dataproduct),
    updateDraftWant: (dataproduct) =>
        requests.post('/DataProduct/draftWant/update', dataproduct),
    findDraftByDataProductCode: (dataProductCode) =>
        requests.get('/DataProduct/draft/' + dataProductCode),
    findDraftProductsByUserId: (userId) =>
        requests.get('/DataProduct/draftProducts/userId/' + userId),
    findDraftWantsByUserId: (userId) =>
        requests.get('/DataProduct/draftWants/userId/' + userId),
    deleteDraft: (dataProductCode) =>
        requests.delete('/DataProduct/draft/' + dataProductCode),


    //PRODUCT/WANT APIs
    findDataProductByDataProductCode: (dataProductCode) =>
        requests.get('/DataProduct/dataProduct/' + dataProductCode),
    findWantByDataProductCode: (dataProductCode) =>
        requests.get('/DataProduct/want/' + dataProductCode),
    findActiveDataProductsByUserId: (userId) =>
        requests.get('/DataProduct/dataProducts/active/userId/' + userId),
    extendDataProductExpiryDate: (userId, dataProductCode, newExpiryDate) =>
        requests.get('/DataProduct/dataProduct/extendExpiryDate/' + userId + '/' + dataProductCode + '/' + newExpiryDate),

};

/*
 * For Organization master data from the Organization Service
 */
const OrganizationMaster = {
    create: (organization) =>
        requests.post('/OrganizationService/private/organization/create', organization),
    // createOrgUser: (organizationinterface) =>
    //     requests.post('/OrganizationService/guest/organization/createuser', organizationinterface),
    createAgent: (organizationinterface) =>
        requests.post('/OrganizationService/private/organization/createagent', organizationinterface),
    createSuperagent: (organizationinterface) =>
        requests.post('/OrganizationService/private/organization/createsuperagent', organizationinterface),
    updateUser: (organizationinterface) =>
        requests.post('/OrganizationService/private/organization/updateUser', organizationinterface),
    updateOrg: (organizationinterface) =>
        requests.post('/OrganizationService/private/organization/update', organizationinterface),
    listOrganizations: () =>
        requests.get('/OrganizationService/organization/findAll'),
    fetchOrganizationByOrgId: (organizationId) =>
        requests.get('/OrganizationService/fetch/byOrganizationId/' + organizationId),
    fetchOrganizationByBpId: (organizationBpId) =>
        requests.get('/OrganizationService/fetch/byOrganizationBpId/' + organizationBpId),
    findOrgNameAlreadyExists: (orgName) =>
        requests.get('/OrganizationService/guest/organization/findOrgNameAlreadyExists/' + orgName),
    listCategories: () =>
        requests.get('/OrganizationService/guest/category/findAll'),
    listCountris: () =>
        requests.get('/OrganizationService/guest/country/findAll'),
    fetchLatestUpdateTime: () =>
        requests.get('/OrganizationService/organization/fetch/latestUpdateTime'),
    findOrganizationNameByBPID: (bpid) =>
        requests.get('/OrganizationService/guest/fetch/organizationNameByBpId/' + bpid),
    findUserAlreadyExists: (userId) =>
        requests.post('/OrganizationService/guest/findUserAlreadyExists', userId),
    generateConfirmationKey: (userID) =>
        requests.post('/OrganizationService/guest/generateConfirmationKey', userID),
    recoverUsername: (Vo) =>
        requests.post('/OrganizationService/guest/recover/username', Vo),
    updatePassword: (PasswordRecoveryVo) =>
        requests.post('/OrganizationService/guest/updatePassword', PasswordRecoveryVo),
    approveOrganization: (organizationInterafce) =>
        requests.post('/OrganizationService/organization/approve', organizationInterafce),
    rejectOrganization: (organizationInterafce) =>
        requests.post('/OrganizationService/organization/reject', organizationInterafce),
    approveUser: (organizationInterafce) =>
        requests.post('/OrganizationService/user/approve', organizationInterafce),
    rejectUser: (organizationInterafce) =>
        requests.post('/OrganizationService/user/reject', organizationInterafce),
    acceptTerms: () =>
        requests.post('/OrganizationService/user/accept/terms'),
    acceptPolicy: () =>
        requests.post('/OrganizationService/user/accept/privacypolicy'),
    fetchTerms: () =>
        requests.get('/OrganizationService/guest/terms'),
    fetchPolicies: () =>
        requests.get('/OrganizationService/guest/privacypolicy'),
    hasAcceptedLatestTerms: () =>
        requests.get('/OrganizationService/check/user/acceptance'),
    enabledisableUser: (Vo) =>
        requests.post('/OrganizationService/private/enabledisable/user', Vo),

};

/*
 * For calls to Solr
 */
const Solr = {
    activeProducts: (q) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&sort=dataProductId%20desc&fq=state:active&fq=isProduct:true'),
    activeWants: (q) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&sort=dataProductId%20desc&fq=state:active&fq=isProduct:false'),
    recentSixProducts: () =>
        requests.get('/solr/dataproductinfo/select?q=*:*&sort=dataProductId%20desc&rows=6&fq=state:active&fq=isProduct:true'),
    recentSixProductsInCategory: (categoryName) =>
        requests.get('/solr/dataproductinfo/select?q=%28categoryName:%22' + encodeURIComponent(categoryName) +
            '%22%29&sort=id%20desc&rows=6&fq=state:active&fq=isProduct:true'),
    recentSixWants: () =>
        requests.get('/solr/dataproductinfo/select?q=*:*&sort=dataProductId%20desc&rows=6&fq=state:active&fq=isProduct:false'),
    recentFourProducts: (categoryId) =>
        requests.get('/solr/dataproductinfo/select?sort=dataProductId%20desc&rows=4&fq=state:active&fq=isProduct:true&q=categoryId:' + categoryId),
    recentFourProductsWithSubCategory: (categoryId, subcategoryId) =>
        requests.get('/solr/dataproductinfo/select?q=%28categoryId:%22' + encodeURIComponent(categoryId) + '%22%29%20AND%20%28subcategoryId:%22' +
            encodeURIComponent(subcategoryId) + '%22%29&sort=dataProductId%20desc&rows=4&fq=state:active&fq=isProduct:true'),
    overallProductRating: (objectCode) =>
        /*requests.get('/solr/ratingSummary/select?q=*:*&fq=objectCode:%22' + encodeURIComponent(objectCode) +'%22')*/
        requests.get('/solr/dataproductinfo/select?q=*:*&fq=dataProductCode:%22' + encodeURIComponent(objectCode) + '%22'),
    activeDataProductsOfAUser: (q, userId) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&sort=dataProductId desc&fq=state:active&fq=isProduct:true&fq=publishedBy:' +
            encodeURIComponent(userId)),
    activeWantsOfAUser: (q, userId) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&sort=dataProductId desc&fq=state:active&fq=isProduct:false&fq=publishedBy:' +
            encodeURIComponent(userId)),
    inactiveDataProductsOfAUser: (q, userId) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=isProduct:true&fq=state:inactive&sort=dataProductId%20desc'),
    inactiveWantsOfAUser: (q, userId) =>
        requests.get('/solr/dataproductinfo/select?q=*' + q + '*&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=isProduct:false&fq=state:inactive&sort=dataProductId desc'),
    inactiveProducts: () =>
        requests.get('/solr/dataproductinfo/select?q=*:*&sort=dataProductId%20desc&fq=state:inactive&fq=isProduct:true&sort=dataProductId%20desc'),
    inactiveWants: () =>
        requests.get('/solr/dataproductinfo/select?q=*:*&sort=dataProductId%20desc&fq=state:inactive&fq=isProduct:false&sort=dataProductId%20desc'),

    newActiveProducts: (start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=state:active&q=*:*&rows=' + rows + '&sort=dataProductId%20desc&start=' + start),
    newActiveWants: (start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=state:active&q=*:*&rows=' + rows + '&sort=dataProductId%20desc&start=' + start),
    newActiveDataProductsOfAUser: (userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:active&q=*:*&rows=' +
            rows + '&sort=dataProductId%20desc&start=' + start),
    newActiveWantsOfAUser: (userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:active&q=*:*&rows=' +
            rows + '&sort=dataProductId%20desc&start=' + start),
    newInactiveDataProductsOfAUser: (userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:inactive&q=*:*&rows=' +
            rows + '&sort=dataProductId%20desc&start=' + start),
    newInactiveWantsOfAUser: (userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:inactive&q=*:*&rows=' +
            rows + '&sort=dataProductId%20desc&start=' + start),

    searchOnActiveProducts: (q, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=state:active&q="' + encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),
    searchOnActiveWants: (q, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=state:active&q="' + encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),
    searchOnActiveDataProductsOfAUser: (q, userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:active&q="' +
            encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),
    searchOnActiveWantsOfAUser: (q, userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:active&q="' +
            encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),
    searchOnInactiveDataProductsOfAUser: (q, userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:true&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:inactive&q="' +
            encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),
    searchOnInactiveWantsOfAUser: (q, userId, start, rows) =>
        requests.get('/solr/dataproductinfo/select?fq=isProduct:false&fq=publishedBy:' + encodeURIComponent(userId) + '&fq=state:inactive&q="' +
            encodeURIComponent(q) + '"&rows=' + rows + '&start=' + start),

};

/*
 * Direct Negotiation Order Service
 */
const DirectNegoOrder = {
    remainingQtyPerpetual: (dataProductCode) =>
        requests.get('/DirectNegotiationOrderService/remainingquantity/' + dataProductCode),
    loadByUserId: (userID) =>
        requests.get('/DirectNegotiationOrderService/myOrders/' + userID),
    subscriptionRemainingQuantity: (dataProductCode, subscriptionCode) =>
        requests.get(' /DirectNegotiationOrderService/remainingquantity/' + dataProductCode + '/' + subscriptionCode),
    getOrder: (orderCode) =>
        requests.get('/DirectNegotiationOrderService/fetchOrder/' + orderCode),
    orderAction: (orderCode, isAccepted) =>
        requests.put('/DirectNegotiationOrderService/order/' + orderCode + '/' + isAccepted),
    orderModifiedAction: (orderCode, termsSet) =>
        requests.putBody('/DirectNegotiationOrderService/order/action/' + orderCode, termsSet),
    createPerpetual: (order) =>
        requests.post('/DirectNegotiationOrderService/createOrder', order),
    createSubscription: (order) =>
        requests.post('/DirectNegotiationOrderService/createSubscriptionOrder', order),
};

/*
 * For accessing User Preferences from User Preference service
 *
 */
const UserPreference = {
    create: (userPreference) =>
        requests.post('/UserPreferenceService/userPreference/create', userPreference),
    updateUserPreference: (userPreference) =>
        requests.post('/UserPreferenceService/userPreference/update', userPreference),
    listUserPreferences: () =>
        requests.get('/UserPreferenceService/userPreference/findAll'),
    loadByUserId: (userID) =>
        requests.get('/UserPreferenceService/userPreferenceByUserId/' + userID),
    loadById: (id) =>
        requests.get('/UserPreferenceService/userPreference/' + id),
    deleteUserPreference: (id) =>
        requests.delete('/UserPreferenceService/userPreference/' + id)
};


/*
 * For Visualization Service which calls Vitellus
 */
const Visualization = {
    createReport: (tradeReferenceCode, userId) =>
        requests.get('/VisualizationService/report/create/' + tradeReferenceCode + '/' + userId),
};

/*
 * For accessing Trade transaction data from TradeService.
 */
const TradeService = {
    loadByUserId: (userID) =>
        requests.get('/TradeService/trade/findByUserId/' + userID),
};

/*
 * For displaying notifications from UserNotificationService.
 */
const Notifications = {
    loadByUserId: (userId) =>
        requests.get('/UserNotificationService/notifications/datamarketplace/usernotification/' + userId),
    displayNotification: (notificationId) =>
        requests.put('/UserNotificationService/notifications/datamarketplace/usernotification/notificationFlag/' + notificationId + '/true'),
    fetchMyTotalUnreadNotifications: () =>
        requests.get('/UserNotificationService/fetch/totalUnreadNotifications'),
    fetchLatestTenNotifications: () =>
        requests.get('/UserNotificationService/fetch/latestNotifications'),
    markAllRead: () =>
        requests.put('/UserNotificationService/notifications/datamarketplace/allusernotification/notificationFlag/' + 'true'),
};
/**
 * For managing Terms ; referring ContractManagementService
 */
const Terms = {
    create: (term) =>
        requests.post('/ContractManagementService/terms/create', term),
    update: (term) =>
        requests.post('/ContractManagementService/terms/update', term),
    delete: (termId) =>
        requests.post('/ContractManagementService/terms/delete/' + termId),
    listAllTerms: () =>
        requests.get('/ContractManagementService/terms/findall/'),
    listAllTermType: () =>
        requests.get('/ContractManagementService/termtype/findall/'),
};

/**
* For Ratings and Reviews
*/
const RatingReviewAndReputation = {
    create: (ratingAndReviewVo) =>
        requests.post('/RatingReviewAndReputationService/submitReview', ratingAndReviewVo),
    createComment: (ratingAndReviewVo, reviewId) =>
        requests.post('/RatingReviewAndReputationService/submitComment/' + reviewId, ratingAndReviewVo),
    update: (ratingAndReviewVo) =>
        requests.put('/RatingReviewAndReputationService/updateReview', ratingAndReviewVo),
    listAllReviews: (objectCode) =>
        requests.get('/RatingReviewAndReputationService/fetchAllReviews/' + objectCode),
    listReviewsBasedOnRatings: (objectCode, rating) =>
        requests.get('/RatingReviewAndReputationService/fetchRatingBasedReviews/' + objectCode + '/' + rating),
    listMyApprovedReviews: (userId) =>
        requests.get('/RatingReviewAndReputationService/fetchMyReviews/' + userId),
    listMyUnapprovedReviews: (userId) =>
        requests.get('/RatingReviewAndReputationService/fetchReviewsPendingForApproval/' + userId),
    deleteReviews: (ratingAndReviewVo) =>
        requests.delete('/RatingReviewAndReputationService/remove', ratingAndReviewVo), /*Should have objectCode and userId*/

};


/*
 * For fetching and listing DP /want from Elastic search
 */
const ElasticDataProduct = {
    newActiveProducts: (start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"true"&sort=dataProductId:desc&size=' + encodeURIComponent(rows) + '&from=' + encodeURIComponent(start)),

    allActiveProducts: () =>
        requests.get('/elasticdataproduct/_count?q=+state:"active"AND+isProduct:"true"'),

    newActiveWants: (start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"false"&sort=dataProductId:desc&size=' + encodeURIComponent(rows) + '&from=' + encodeURIComponent(start)),

    allActiveWants: (start, rows) =>
        requests.get('/elasticdataproduct/_count?q=+state:"active"AND+isProduct:"false"'),

    inactiveElasticProduct: (start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"inactive"AND+isProduct:"true"&sort=dataProductId:desc&size=' + encodeURIComponent(rows) + '&from=' + encodeURIComponent(start)),
    //Ref: http://localhost:9200/elasticdataproduct/_search?q=state:"active"AND+isProduct:"true"&sort=dataProductId:desc&size=4&from=0

    inactiveElasticWants: (start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"inactive"AND+isProduct:"false"&sort=dataProductId:desc&size=' + encodeURIComponent(rows) + '&from=' + encodeURIComponent(start)),
    //Ref: http://localhost:9200/elasticdataproduct/_search?q=state:"active"AND+isProduct:"false"&sort=dataProductId:desc&size=4&from=0

    recentFourProductsWithSubCategory: (categoryId, subcategoryId) =>
        //requests.get('/solr/dataproductinfo/select?q=%28categoryId:%22' + encodeURIComponent(categoryId) + '%22%29%20AND%20%28subcategoryId:%22' +
        //       encodeURIComponent(subcategoryId) + '%22%29&sort=dataProductId%20desc&rows=4&fq=state:active&fq=isProduct:true'),

        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"true"&sort=dataProductId:descAND+categoryId:"' + encodeURIComponent(categoryId) + '"AND+subcategoryId:"' + encodeURIComponent(subcategoryId) + '"&size=4'),

    recentSixProducts: () =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"true"&sort=dataProductId:desc&size=6'),

    recentSixProductsInCategory: (categoryId) =>
        requests.get('/elasticdataproduct/_search?q=+isProduct:"true"AND+categoryId:"' + encodeURIComponent(categoryId) + '"AND+state:"active"&sort=dataProductId:desc&size=6'),

    recentSixWants: () =>
        requests.get('/elasticdataproduct/_search?q=+isProduct:"false"AND+state:"active"&sort=dataProductId:desc&size=6'),

    recentSixWantsInCategory: (categoryId) =>
        requests.get('/elasticdataproduct/_search?q=+isProduct:"false"AND+categoryId:"' + encodeURIComponent(categoryId) + '"AND+state:"active"&sort=dataProductId:desc&size=6'),

    recentFourProducts: (categoryId) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"true"ANDcategoryId:"' + encodeURIComponent(categoryId) + '"&sort=dataProductId:desc&size=4'),

    recentFourWants: (categoryId) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"false"ANDcategoryId:"' + encodeURIComponent(categoryId) + '"&sort=dataProductId:desc&size=4'),

    recentFourProductsWithSubCategory: (categoryId, subcategoryId) =>
        requests.get('/elasticdataproduct/_search?q=+isProduct:"true"AND+state:"active"AND+categoryId:"' + encodeURIComponent(categoryId) + '"AND+subcategoryId:"' + encodeURIComponent(subcategoryId) + '"&sort=dataProductId:desc&size=4'),
    recentFourWantsWithSubCategory: (categoryId, subcategoryId) =>
        requests.get('/elasticdataproduct/_search?q=+isProduct:"false"AND+state:"active"AND+categoryId:"' + encodeURIComponent(categoryId) + '"AND+subcategoryId:"' + encodeURIComponent(subcategoryId) + '"&sort=dataProductId:desc&size=4'),

    newActiveDataProductsOfAUser: (userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"&sort=dataProductId:desc&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    allActiveDataProductsOfAUser: (userId) =>
        requests.get('/elasticdataproduct/_count?q=+state:"active"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"'),

    newActiveWantsOfAUser: (userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"active"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"&sort=dataProductId:desc&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    allActiveWantsOfAUser: (userId) =>
        requests.get('/elasticdataproduct/_count?q=+state:"active"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"'),

    newInactiveDataProductsOfAUser: (userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"inactive"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"&sort=dataProductId:desc&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    allInactiveDataProductsOfAUser: (userId) =>
        requests.get('/elasticdataproduct/_count?q=+state:"inactive"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"'),

    newInactiveWantsOfAUser: (userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=+state:"inactive"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"&sort=dataProductId:desc&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    allInactiveWantsOfAUser: (userId) =>
        requests.get('/elasticdataproduct/_count?q=+state:"inactive"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"'),

    overallProductRating: (objectCode) =>
        requests.get('/elasticdataproduct/_search?q=+dataProductCode:"' + encodeURIComponent(objectCode) + '"'),

    searchOnActiveProducts: (q, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"active"AND+isProduct:"true"' + '&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    searchOnActiveWants: (q, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"active"AND+isProduct:"false"' + '&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    searchOnActiveDataProductsOfAUser: (q, userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"active"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    searchOnActiveWantsOfAUser: (q, userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"active"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    searchOnInactiveDataProductsOfAUser: (q, userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"inactive"AND+isProduct:"true"AND+publishedBy:"' + encodeURIComponent(userId) + '"&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
    searchOnInactiveWantsOfAUser: (q, userId, start, rows) =>
        requests.get('/elasticdataproduct/_search?q=*:"' + encodeURIComponent(q) + '"AND+state:"inactive"AND+isProduct:"false"AND+publishedBy:"' + encodeURIComponent(userId) + '"&from=' + encodeURIComponent(start) + '&size=' + encodeURIComponent(rows)),
};

const ElasticDcn = {
    elasticdogcontrolnotice: (query) =>
        token.post('/elasticdogcontrolnotice/elasticdcn/_search', query),
};
const DCNService = {
    findDcnDetailsByDcnId: (dcnId) =>
        requests.get('/DCNService/dcn/fetch/' + dcnId),
    createDCN: (Vo) =>
        requests.post('/DCNService/dcn/create', Vo),
    createDcnDraft: (Vo) =>
        requests.post('/DCNService/dcn/draft', Vo),
    uploadDcn: (formdata) =>
        requests.post('/DCNService/upload/dcn', formdata),
    uploadfiles: (formdata) =>
        requests.post('/DCNService/upload/files', formdata),
    listAllFiles: (formCode) =>
        requests.get('/DCNService/listfiles/' + formCode),
    deleteFile: (formCode, filename, fileType) =>
        requests.post('/DCNService/deletefile/' + formCode + '/' + filename + '/' + fileType),
    downloadFile: (formCode, filename) =>
        requests.get('/DCNService/downloadfile/' + formCode + '/' + encodeURIComponent(filename)).responseType('blob'),
    downloadTemplateFile: (filename) =>
        requests.get('/DCNService/download/dcn/template/' + encodeURIComponent(filename)).responseType('blob'),
    downloadAllDcns: (myBpidFilter) =>
        requests.get('/DCNService/dcn/fetchAllDcnInfo/' + myBpidFilter).responseType('blob'),
    getAllDogBreed: () =>
        requests.get('/DCNService/fetchall/dogbreed'),
    dischargeDcn: (Vo) =>
        requests.post('/DCNService/discharge/dcn', Vo),
    suspendDcn: (Vo) =>
        requests.post('/DCNService/suspend/dcn', Vo),
    reactivateDcn: (Vo) =>
        requests.post('/DCNService/reactivate/dcn', Vo),
    updateDcn: (dcnId) =>
        requests.get('/DCNService/dcn/update/' + dcnId),
    fetchDraftDcns: () =>
        requests.get('/DCNService/fetch/dcndrafts'),
    deleteDraftDcn: (dcnId) =>
        requests.del('/DCNService/delete/dcndraft/' + dcnId),
    deleteAllDraftDcn: () =>
        requests.del('/DCNService/delete/all/dcndrafts'),
    fetchVariations: (dcnId) =>
        requests.get('/DCNService/fetchvariations/dcn/' + dcnId),
    initiateTransfer: (Vo) =>
        requests.post('/DCNService/initiate/transfer/dcn', Vo),
    fetchTransferDcnList: (status) =>
        requests.get('/DCNService/fetch/transferdcnlist/' + status),
    acceptTransfer: (transferId) =>
        requests.get('/DCNService/private/acceptTransfer/' + transferId),
    rejectTransfer: (transferId) =>
        requests.get('/DCNService/private/rejectTransfer/' + transferId),
    addressLookUp: (Vo) =>
        requests.post('/DCNService/private/addresslookup', Vo),
    alreadyInitiatedTransfer: (dcnId) =>
        requests.get('/DCNService/check/transfer/alreadyinitiated/' + dcnId),
    fetchAvailableReports: () =>
        requests.post('/DCNService/fetch/available/reports'),
    requestReport: (Vo) =>
        requests.post('/DCNService/request/report', Vo),
    getChartCode: (Vo) =>
        requests.post('/DCNService/get/chartcode', Vo),
    getChartDetailsByChartCode: (chartVo) =>
        requests.post('/DCNService/fetch/chartConfigBy', chartVo),
    downloadReportExcel: (chartVo) =>
        requests.post('/DCNService/download/dcn/statistics', chartVo).responseType('blob'),

};

const HelpdeskService = {
    fetchHelpdeskProblems: () =>
        requests.get('/HelpdeskService/guest/fetch/problems'),
    fetchHelpdeskProblemsPrivate: () =>
        requests.get('/HelpdeskService/fetch/problems'),
    raiseQueryGuest: (Vo) =>
        requests.post('/HelpdeskService/guest/raise/query', Vo),
    raiseQuery: (Vo) =>
        requests.post('/HelpdeskService/private/raise/query', Vo),
    uploadfiles: (formdata) =>
        requests.post('/HelpdeskService/guest/upload/files', formdata),
    fetchHelpdeskQueryData: (calledFrom) =>
        requests.get('/HelpdeskService/private/fetch/dashboard/' + calledFrom),
    submitIssueResponse: (Vo) =>
        requests.post('/HelpdeskService/private/issue/respond', Vo),
    closeIssue: (Vo) =>
        requests.post('/HelpdeskService/private/issue/close', Vo),
    reassignIssue: (Vo) =>
        requests.post('/HelpdeskService/private/issue/reassign', Vo),
    listAllFiles: (formCode) =>
        requests.get('/HelpdeskService/listfiles/' + formCode),
    deleteFile: (formCode, filename, fileType) =>
        requests.post('/HelpdeskService/deletefile/' + formCode + '/' + filename + '/' + fileType),
    downloadFile: (formCode, filename) =>
        requests.get('/HelpdeskService/downloadfile/' + formCode + '/' + encodeURIComponent(filename)).responseType('blob'),
    fetchIssueHistory: (issueId) =>
        requests.get('/HelpdeskService/fetch/issueHistory/' + issueId),
};

const ElasticMetaData = {
    searchMetaData: (key) =>
        requests.get('/elasticmetadata/_search?q=*:' + key)
};

/*
 * For Data Delivery
 */
const Delivery = {
    myPurchasesList: () =>
        //requests.get('/DataDeliveryService/myPurchases/'+userId),
        requests.get('/ContractManagementService/myPurchases/'),
    accessData: (transactionId) =>
        requests.post('/DataDeliveryService/api/datadelivery/getdataFromTradeReferenceCode/autokeygeneration/' + transactionId, ''),
    userStatusForReviewing: (userId, dataProductCode, isProduct) =>
        requests.get('/DataDeliveryService/checkIsBuyer/' + userId + '/' + dataProductCode + '/' + isProduct),
};
/**
 * Zeppelin Service
 */
const Zeppelin = {

    accessParagraph: (notebookId, paragraphId) =>
        requests.get('/ZeppelinService/api/notebook/' + notebookId + '/paragraph/' + paragraphId),
    accessNotebook: (notebookId) =>
        requests.get('/ZeppelinService/api/notebook/' + notebookId),

};

const Analytics = {
    accessNotebook: (userId) =>
        requests.get('/AnalyticsService/notebook/' + userId),
    create: (userId, bpid, dataProductCode) =>
        requests.post('/AnalyticsService/notebook/create/' + userId + "/" + bpid + "/" + dataProductCode),
    createSimple: (userId, bpid, notebookName) =>
        requests.post('/AnalyticsService/notebook/' + userId + "/" + bpid + "/" + notebookName),
    fetchQuery: (userId, bpid, dataProductCode) =>
        requests.post('/AnalyticsService/query/' + userId + "/" + bpid + "/" + dataProductCode),
    modelCreation: (data) =>
        requests.post('/AnalyticsService/pipeline/create', data),
    accessPipeLine: (pipelineType, pageNumber, pageSize) =>
        requests.get('/AnalyticsService/pipeline/' + pipelineType + '/' + pageNumber + '/' + pageSize),
    runAllParagraph: (pipelineCode) =>
        requests.post('/AnalyticsService/pipeline/run/' + pipelineCode),
    clonePipeline: (pipelineCode) =>
        requests.get('/AnalyticsService/pipeline/clone/' + pipelineCode),
    pipelineProgress: (pipelineCode) =>
        requests.post('/AnalyticsService/pipeline/progress/' + pipelineCode),
    visualizeUpdateGraph: (pipeline) =>
        requests.putBody('/AnalyticsService/pipeline/visualize/update', pipeline),
    visualizeSaveGraph: (pipeline) =>
        requests.post('/AnalyticsService/pipeline/visualize/save', pipeline),
    getVisualizePipelines: (pageNumber, pageSize) =>
        requests.get('/AnalyticsService/pipeline/' + pageNumber + '/' + pageSize),
    getDataFrameAttributes: (pipelineCode) =>
        requests.get('/AnalyticsService/pipeline/attribute/fetch/' + pipelineCode),
    getVisualizeIframe: (dataProductCode) =>
        requests.post('/AnalyticsService/data/download/' + dataProductCode),
    getPreprocessingTechnique: () =>
        requests.get('/AnalyticsService/fetch/preprocessingTechniques'),
    savePreprocessingStep: (data) =>
        requests.post('/AnalyticsService/pipeline/preprocessingStep/save', data),
    loadDatasetOnZeppelin: (dataset) =>
        requests.post('/AnalyticsService/pipeline/loadDataproduct', dataset),
    runPreprocessingStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/preprocessingStep/run', Vo),
    fetchDatasetsByPipelineCode: (Vo) =>
        requests.post('/AnalyticsService/fetch/datasetsbypipelinecode/', Vo),
    saveProvisionDataStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/provisionStep/save', Vo),
    runProvisionDataStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/provisionStep/run', Vo),
    fetchAllMyPipelines: (pipelineType, pageNumber, pageSize) =>
        requests.get('/AnalyticsService/pipeline/' + pipelineType + '/' + pageNumber + '/' + pageSize),
    fetchAllMyPipelinesCount: (pipelineType) =>
        requests.get('/AnalyticsService/pipeline/count/' + pipelineType),
    fetchPipelineStatus: (pipelineCode) =>
        requests.get('/AnalyticsService/fetch/pipelineStatus/' + pipelineCode),
    runFullPipeline: (pipelineCode) =>
        requests.post('/AnalyticsService/run/pipeline/' + pipelineCode),
    checkDatasetNameExistOrNot: (Vo) =>
        requests.post('/AnalyticsService/fetch/datasetName/ExistsOrNot', Vo),
    fetchFullPipelineVo: (pipelineCode) =>
        requests.post('/AnalyticsService/fetch/pipeline/bypipelineCode/' + pipelineCode),
    editPipelineSteps: (Vo) =>
        requests.post('/AnalyticsService/edit/pipeline/steps', Vo),
    deletePipelineStep: (Vo) =>
        requests.delBody('/AnalyticsService/pipeline/step/delete', Vo),
    validatePipelineName: (pipelineName) =>
        requests.get('/AnalyticsService/fetch/pipelineName/ExistsOrNot/' + pipelineName),
    getPlotlyJsonData: (Vo) =>
        requests.post('/AnalyticsService/create/plotly/charts', Vo),
    saveJoinStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/joinStep/save', Vo),
    runJoinStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/joinStep/run', Vo),
    fetchDatasetDetails: (dsCode) =>
        requests.get('/AnalyticsService/fetch/DatasetDetails/byDatasetCode/' + dsCode),
    savePlotlyChart: (Vo) =>
        requests.post('/AnalyticsService/save/plotly/charts', Vo),
    getChartListByPipelineCode: (pipelineCode) =>
        requests.get('/AnalyticsService/get/chartList/' + pipelineCode),
    getChartDetailsByChartCode: (chartCode) =>
        requests.get('/AnalyticsService/get/chartDetailsByCode/' + chartCode),
    saveAdvanceStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/advancedStep/save', Vo),
    runAdvanceStep: (Vo) =>
        requests.post('/AnalyticsService/pipeline/advancedStep/run', Vo),
    copyPipelineByPipelineCode: (pipelineCode) =>
        requests.post('/AnalyticsService/clone/pipelineByPipelineCode/' + pipelineCode),
};

const ContractManagementService = {
    //Term master details to construct Terms on UI
    fetchTermMasterData: () =>
        requests.get('/ContractManagementService/termsMaster/fetchAllDetails'),
    //Subscription details are now manged by Contract Managemnt Service
    listSubscriptionPackages: () =>
        requests.get('/ContractManagementService/subscription/package/fetchAll'),
    createSaleContract: (SaleContractDetailsVo) =>
        requests.post('/ContractManagementService/salecontract/create/', SaleContractDetailsVo),
    fetchSaleContract: (contractCode) =>
        requests.get('/ContractManagementService/fetchSaleContract/' + contractCode),
    negotiateOrder: (SaleContractDetailsVo) =>
        requests.post('/ContractManagementService/order/negotiation/', SaleContractDetailsVo),
    mySalecontracts: () =>
        requests.get('/ContractManagementService/mySalecontracts/'),
}
const DataProductV2 = {
    publishDraftProduct: (dataproduct) =>
        requests.post('/DataProductService/publish/draft/dataProduct/', dataproduct),
    publishDraftWant: (dataproduct) =>
        requests.post('/DataProductService/draftWant/publish', dataproduct),
    findDataProductByDataProductCode: (dataProductCode) =>
        requests.get('/DataProductService/dataProduct/fetch/detailedInformation/' + dataProductCode),
    validateConsent: (data) =>
        requests.post('/DataProductService/draftProduct/validateconsent', data),
    validateDataSource: (dataSourceParent) =>
        requests.post('/DataProductService/dataSource/validateConnection/', dataSourceParent),
    //Draft APIs
    createDraftProduct: (dataproduct) =>
        requests.post('/DataProductService/draftProduct/create', dataproduct),
    createDraftWant: (dataproduct) =>
        requests.post('/DataProductService/draftWant/create', dataproduct),
    updateDraftProduct: (dataproduct) =>
        requests.post('/DataProductService/draftProduct/update', dataproduct),
    updateDraftWant: (dataproduct) =>
        requests.post('/DataProductService/draftWant/update', dataproduct),
    findDraftByDataProductCode: (dataProductCode) =>
        requests.get('/DataProductService/draft/' + dataProductCode),
    findDraftProductsByUserId: (userId) =>
        requests.get('/DataProductService/draftProducts/userId/' + userId),
    findDraftWantsByUserId: (userId) =>
        requests.get('/DataProductService/draftWants/userId/' + userId),
    deleteDraft: (dataProductCode) =>
        requests.delete('/DataProductService/draft/' + dataProductCode),
    findAttributeOfDataProductByDataProductCode: (dataProductCode) =>
        requests.get('/DataProductService/attribute/fetch/' + dataProductCode),


}
const OrderService = {
    createSubscription: (OrderVo) =>
        requests.post('/OrderService/create/order', OrderVo)
}
const FileHandlerService = {
    uploadfile: (formdata) =>
        requests.post('/FileHandlerService/upload/file', formdata),
    preview: (val) =>
        requests.post('/FileHandlerService/preview/file', val),

}
export default {
    Auth,
    UserManagementService,
    Draft,
    CategoryMaster,
    SubscriptionMaster,
    AttributeMaster,
    AttributeTemplateMaster,
    DataProduct,
    OrganizationMaster,
    Solr,
    DirectNegoOrder,
    Visualization,
    TradeService,
    Notifications,
    Terms,
    RatingReviewAndReputation,
    ElasticDataProduct,
    Delivery,
    Zeppelin,
    Analytics,
    ContractManagementService,
    DataProductV2,
    OrderService,
    FileHandlerService,
    MetaDataService,
    ElasticMetaData,
    ElasticDcn,
    DCNService,
    HelpdeskService,
    dogbreed
}
